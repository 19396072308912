/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip:before {
  display: block;
  overflow: auto;
  opacity: 0;
  transition: .15s linear 1s;
  content: attr(data-text); /* here's the magic */
  position:absolute;
  
  /* vertically center */
  top:50%;
  transform:translateY(-50%);
  
  /* move to right */
  left:100%;
  width: fit-content;
  margin-left:10px; /* and add a small left margin */
  overflow-x: visible;
  white-space: nowrap;
  
  @apply shadow bg-white text-black py-1 px-2 text-xs font-thin rounded
}

.tooltip:after {
  content: "";
  position:absolute;
  
  /* position tooltip correctly */
  left:100%;
  margin-left:-7px;
 
  /* vertically center */
  top:50%;
  transform:translateY(-50%);
 
  /* the arrow */
  border:10px solid white;
  border-color: transparent white transparent transparent;
  
  opacity:0;
  transition: .15s linear 1s;
}

.tooltip {
  position: relative;
}

.tooltip:hover:before {
  opacity:1;
}
.tooltip:hover:after {
  opacity:1;
}

.react-flow__node.selected .query-node {
  @apply bg-blue-100
}

.expand-last-column td:last-child {
  width: auto;
  table-layout: fixed;
}

.last-col-no-border td:last-child {
  border-right: none;
}

.last-col-no-border th:last-child {
  border-right: none;
}